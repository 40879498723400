<template>
  <div id="payeeManage">
    <div class="common-card-body">
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_192') }}: </span>
          <a-input
            v-model:value="searchQuery.accountName"
            allow-clear
            @pressEnter="loadPayeeList(1)"
            @blur="loadPayeeList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_193') }}: </span>
          <a-input
            v-model:value="searchQuery.accountNo"
            allow-clear
            @pressEnter="loadPayeeList(1)"
            @blur="loadPayeeList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_198') }}: </span>
          <a-select
            v-model:value="searchQuery.accountCurrency"
            allow-clear
            show-search
            option-label-prop="label"
            option-filter-prop="label"
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="item in curList"
              :key="item.currencyCode"
              :value="item.currencyCode"
              :label="`${item.currencyCode} - ${item.currencyName}`"
            >
              <img :src="getCountryLogo(item.imgName || item.currencyCode)" class="select-img-country">
              <span>{{ item.currencyCode }} - {{ item.currencyName }}</span>
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_196') }}: </span>
          <a-select
            v-model:value="searchQuery.paymentType"
            allow-clear
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="item in paymentTypeList"
              :key="item.code"
              :value="item.code"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_078') }}: </span>
          <a-select
            v-model:value="searchQuery.paymentMode"
            allow-clear
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="item in paymentModeList"
              :key="item.code"
              :value="item.code"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_114') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="item in statusList"
              :key="item.code"
              :value="item.code"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="payees"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'paymentType'">
              <div class="flex-align">
                <div :class="record.paymentType === 'B2C' ? 'circle-person' : 'circle-company'" />
                <span style="margin-left: 5px">{{ record.paymentTypeText }}</span>
              </div>
            </template>
            <template v-if="column.key === 'accountCurrency'">
              <div class="flex-align">
                <img style="width: 21px; height: 13px" :src="getCountryLogo(record.accountCurrency)">
                <span style="margin-left: 5px">{{ record.accountCurrency }}</span>
              </div>
            </template>
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%">
                <a-button
                  size="small"
                  type="text"
                  @click="$router.push({ path: '/funds/outbounds/payee_edit', query: { payeeId: record.payeeId, type: 'detail' }})"
                >{{ $t('pages_pay_119') }}</a-button>
                <a-divider style="margin: 0 -12px" type="vertical" />
                <a-button
                  size="small"
                  type="link"
                  @click="$router.push({ path: '/funds/outbounds/payee_edit', query: { payeeId: record.payeeId, type: 'update' }})"
                >{{ $t('pages_pay_199') }}</a-button>
                <a-divider style="margin: 0 -12px" type="vertical" />
                <a-popconfirm :title="$t('pages_pay_200')" @confirm="() => deletePayee(record.payeeId)">
                  <a-button
                    size="small"
                    type="link"
                    danger
                  >{{ $t('pages_pay_201') }}</a-button>
                </a-popconfirm>
                <a-button
                  v-if="record.status === '01'"
                  shape="round"
                  size="small"
                  type="primary"
                  style="margin-left: 18px"
                  @click="$router.push({ path: '/funds/outbounds/single_apply', query: { payeeId: record.payeeId }})"
                >{{ $t('pages_pay_191') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import TablePage from '@/components/table-page'
import { _pay, _receive } from '@/api'
import { getAttrStatusList, getTradeStatusName, getCountryLogo, getCountryName } from '@/util'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'PayeeManage',
  components: {
    'table-page': TablePage
  },
  setup () {
    const state = reactive({
      loading: false,
      exportLoading: false,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        accountName: '',
        accountNo: '',
        accountCurrency: '',
        paymentType: '',
        status: ''
      },
      total: 0,
      accountNames: [],
      areaPubList: [],
      curList: [],
      payees: [],
      columns: [
        { key: 'accountName', dataIndex: 'accountName', title: i18n.global.t('pages_pay_377'), width: 200, ellipsis: true },
        { key: 'accountNo', dataIndex: 'accountNo', title: i18n.global.t('pages_pay_378'), width: 200 },
        { key: 'accountCountry', dataIndex: 'accountCountryText', title: i18n.global.t('pages_pay_379'), width: localStorage.getItem('locale-language') === 'en' ? 220 : 180 },
        { key: 'bankName', dataIndex: 'bankName', title: i18n.global.t('pages_pay_380'), width: 200, ellipsis: true },
        { key: 'paymentType', dataIndex: 'paymentTypeText', title: i18n.global.t('pages_pay_089'), width: localStorage.getItem('locale-language') === 'en' ? 200 : 150 },
        { key: 'paymentMode', dataIndex: 'paymentModeText', title: i18n.global.t('pages_pay_078'), width: 180 },
        { key: 'swiftCode', dataIndex: 'swiftCode', title: 'SWIFT CODE', width: 150, ellipsis: true },
        { key: 'accountCurrency', title: i18n.global.t('pages_pay_198'), width: localStorage.getItem('locale-language') === 'en' ? 150 : 120 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_pay_114'), width: 120 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 350 : 300 }
      ]
    })

    state.paymentTypeList = computed(() => {
      return getAttrStatusList('outboundPayeeType')
    })

    state.paymentModeList = computed(() => {
      return getAttrStatusList('paymentMode')
    })

    state.statusList = computed(() => {
      const opts = getAttrStatusList('payeeStatus')
      return opts.splice(1, 3)
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadPayeeList()
    }

    const deletePayee = (payeeId) => {
      _pay.outboundPayeeDel({ payeeId })
        .then(res => {
          if (res.data.succ) {
            message.success(i18n.global.t('pages_pay_203'))
            loadPayeeList()
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const exportPayee = async () => {
      state.exportLoading = true
      await _pay.exportOutboundPayee(state.searchQuery)
      state.exportLoading = false
    }

    const loadCurList = async () => {
      const res = await _receive.getAllCurrency()
      if (res.data.succ) {
        state.curList = res.data.data
      }
    }

    const loadAreaPub = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) {
        state.areaPubList = res.data.data
      }
    }

    const loadPayeeList = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      const res = await _pay.getOutboundPayeeList(state.searchQuery)
      if (res.data.succ) {
        state.payees = res.data.data.pageInfo.list.map(item => {
          return Object.assign({}, item, {
            accountCountryText: `${getCountryName(item.accountCountry, state.areaPubList)}` || '-',
            paymentTypeText: getTradeStatusName(item.paymentType, 'outboundPayeeType'),
            paymentModeText: getTradeStatusName(item.paymentMode, 'paymentMode'),
            statusText: getTradeStatusName(item.status, 'payeeStatus')
          })
        })
        state.total = res.data.data.pageInfo.total
      }
      state.loading = false
    }

    const init = async () => {
      await loadAreaPub()
      loadCurList()
      loadPayeeList()
    }

    return {
      init,
      exportPayee,
      updatePage,
      deletePayee,
      loadPayeeList,
      getCountryLogo,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#payeeManage {
  text-align: left;
  .common-card-body {
    margin-top: 0;
    padding: 0;
    .search-form {
      margin-top: 8px;
      .part-label {
        width: 150px;
      }
    }
    .table-part {
      margin-top: 12px;
      .circle-person, .circle-company {
        width: 6px;
        height: 6px;
        background: @success-color;
        margin-right: 8px;
        border-radius: 50%;
      }
      .circle-company {
        background: @main-color;
      }
    }
  }
}
</style>
